import React, { useState } from "react";
import "font-awesome/css/font-awesome.min.css";
import { useNavigate } from "react-router-dom";
import Axios from "../../axios";

function Loginpage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") setEmail(value);
    else if (name === "password") setPassword(value);
    else if (name === "rememberMe") setRememberMe(!rememberMe);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Perform form validation
    const errors = {};
    if (!email) errors.email = "Email is required";
    if (!password) errors.password = "Password is required";

    if (Object.keys(errors).length === 0) {
      try {
        // Your login logic using axios
        const login = await Axios.post(`/login`, {
          email,
          password,
          rememberMe,
        });
        if (login.data.token) {
          localStorage.setItem("react_app_token", login.data.token);
          navigate("/pagenavigator");
        } else {
          alert(login.data.message);
        }
      } catch (error) {
        // Handle login error
        console.error("Login error:", error);
        alert("Not Found...");
        navigate("/");
      }
    } else {
      // Set form errors
      setFormErrors(errors);
    }
  };

  return (
    <div>
      <>
        {/* Section: Design Block */}
        <section className="">
          {/* Jumbotron */}
          <div
            className="px-4 py-5 px-md-5 text-center text-lg-start"
            style={{ backgroundColor: "hsl(0, 0%, 96%)" }}
          >
            <div className="container">
              <div className="row gx-lg-5 align-items-center">
                <div className="col-lg-3 mb-5 mb-lg-0">
                  {/* <h1 className="my-5 display-3 fw-bold ls-tight">
                    The best offer <br />
                    <span className="text-primary">for your business</span>
                  </h1>
                  <p style={{ color: 'hsl(217, 10%, 50.8%)' }}>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet,
                    itaque accusantium odio, soluta, corrupti aliquam quibusdam
                    tempora at cupiditate quis eum maiores libero veritatis? Dicta
                    facilis sint aliquid ipsum atque?
                  </p> */}
                </div>
                <div className="col-lg-6 mb-5 mb-lg-0">
                  <div className="card">
                    <div className="card-body py-5 px-md-5">
                      <form>
                        <h3>Login</h3>
                        <br></br>
                        <div className="row"></div>
                        {/* Email input */}
                        <div className="form-outline mb-4">
                          <input
                            type="email"
                            id="form3Example3"
                            className={`form-control ${
                              formErrors.email ? "is-invalid" : ""
                            }`}
                            name="email"
                            value={email}
                            onChange={handleInputChange}
                          />
                          <label className="form-label" htmlFor="form3Example3">
                            Email address
                          </label>
                          {formErrors.email && (
                            <div className="invalid-feedback">
                              {formErrors.email}
                            </div>
                          )}
                        </div>

                        {/* Password input */}
                        <div className="form-outline mb-4">
                          <input
                            type="password"
                            id="form3Example4"
                            className={`form-control ${
                              formErrors.password ? "is-invalid" : ""
                            }`}
                            name="password"
                            value={password}
                            onChange={handleInputChange}
                          />
                          <label className="form-label" htmlFor="form3Example4">
                            Password
                          </label>
                          {formErrors.password && (
                            <div className="invalid-feedback">
                              {formErrors.password}
                            </div>
                          )}
                        </div>

                        {/* Checkbox */}
                        <div className="form-check d-flex justify-content-center mb-4">
                          <input
                            className="form-check-input me-2"
                            type="checkbox"
                            id="form2Example33"
                            name="rememberMe"
                            checked={rememberMe}
                            onChange={handleInputChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="form2Example33"
                          >
                            Remember Me
                          </label>
                        </div>

                        {/* Submit button */}
                        <button
                          type="submit"
                          onClick={handleSubmit}
                          className="btn btn-primary btn-block mb-4"
                        >
                          Sign in
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Jumbotron */}
        </section>
        {/* Section: Design Block */}
      </>
    </div>
  );
}

export default Loginpage;
