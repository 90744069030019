import React, { useState,useEffect } from "react";
import Axios from "../../axios";
import { Link, useNavigate } from "react-router-dom";
import "./Filesender.css";
import OPlist from "./OPlist";
import BankbalPopup from "./BankbalPopup";

function App2Stp() {

  const [enableMail, setEnableMail] = useState(false);


  const [data_date, setData_date] = useState([]);
  const [formatedZerothsfile_date,setFormatedZerothsfile_date]=useState('')

  function formatdate(dateString) {
    // Parsing the date string
    let dateParts = dateString.split("/");
    let month = dateParts[0];
    let day = dateParts[1];
    let year = dateParts[2];

    // Formatting the date in the desired format
    return `${day}.${month}.${year}`;
}

  const navigate = useNavigate();
  useEffect(() => {
    fetchData();
   
  }, []);

  useEffect(() => {
    if (data_date.length > 0) {
        const holdingReport = data_date[0].file_uploaded_date;

        // Extracting the date from the string
        let dateStr = holdingReport.split(": ")[1];

        const formattedDate = formatdate(dateStr);
        setFormatedZerothsfile_date(formattedDate);
    }
}, [data_date]);

  let fetchData = async () => {
    try {
      const res = await Axios.post("/get_forSTPfileOP");
      
      if(res.data.length>0){
        setData_date(res.data);
        setUpdateBankbalance(data_date[0].bankbalance)
      }else{
        alert("Files Not uploaded in Software. Reupload and Try Again...")
        navigate("/converterv1");
        console.log(res.data+"No uploaded file")
      }

      
    
    } catch (error) {
      console.log(error);
    }
  };


  const [files, setFiles] = useState([]);
  const [inputData, setInputData] = useState("");

  const [alertmsgsuccess, setAlertmsgsuccess] = useState(false);
  const [alertmsgfailure, setalertmsgfailure] = useState(false);

  const [forsubbtn, setSubbtn] = useState(false);

  const [alertpop,setAlertpop]=useState(false)
  const [updateBankbalance,setUpdateBankbalance]=useState(0)

  //const [alertpopup, setAlertpoup] = useState(false);

  const [confirmBankbalance_val,setConfirmBankbalance_val]=useState(false)

  const handleFileChange = (e) => {
    try{
    const selectedFiles = e.target.files;

    if (files.length === 0) {
      const fileName = e.target.files[0].name.toLowerCase();
      if (fileName.includes("trade")) {
        //console.log('File name includes holdingreport details');
        // setFiles([...files, ...selectedFiles]);
      } else {
        alert("File name does not include trade");
        window.location.reload();
      }
    }

    if (files.length === 2) {
      setFiles([...files, ...selectedFiles]);
    }
    if (files.length === 0) {
      // Get the original name of the uploaded file
  let inputString = e.target.files[0].name
  console.log(e.target.files[0])
 // console.log(e.target.files[0])
  // Extracting the date from the string
 
  const dateStr = inputString.split(" ").pop();
  

  // Parsing the file date string
  const fileDateParts = dateStr.split(".");
  const fileDay = parseInt(fileDateParts[0]);
  const fileMonth = parseInt(fileDateParts[1]) - 1; // JavaScript months are 0-based
  const fileYear = parseInt(fileDateParts[2]);

  // Creating Date object for file date
  const fileDate = new Date(fileYear, fileMonth, fileDay);

  // Creating Date object for holding report date
  const holdingReportDateParts = formatedZerothsfile_date.split(".");
  const holdingReportDay = parseInt(holdingReportDateParts[0]);
  const holdingReportMonth = parseInt(holdingReportDateParts[1]) - 1; // JavaScript months are 0-based
  const holdingReportYear = parseInt(holdingReportDateParts[2]);
  const holdingReportDate = new Date(holdingReportYear, holdingReportMonth, holdingReportDay);
    
  
    // Comparing dates
    if (holdingReportDate.getTime() === fileDate.getTime()) {
      const proceed = window.confirm("This is Previous day STP file . Are you sure you want to proceed?");
      if (proceed) {
        // Proceed with the action
        setFiles([...files, ...selectedFiles]);
       
      } else {
        alert("If there is no Previous day STP file then click 'Proceed without trade file Button' and this Operation is cancelled");
        window.location.reload();
      }
    } else if (holdingReportDate > fileDate) {
      alert("wrong file check the STP file date its looks like old STP file");
      window.location.reload();
    }

    if(files.length<=0){
    
    if (holdingReportDate <= fileDate) {


      // Creating Date object for holding report date
      const stpfiledate = data_date[0].stp_File_uploded_date.split(".");
      const stpfiledateDay = parseInt(stpfiledate[0]);
      const stpfiledateMonth = parseInt(stpfiledate[1]) - 1; // JavaScript months are 0-based
      const stpfiledateYear = parseInt(stpfiledate[2]);
      const stpfiledate_Date = new Date(stpfiledateYear, stpfiledateMonth, stpfiledateDay);


          console.log(stpfiledate_Date)
          console.log(fileDate)
         
      if(stpfiledate_Date.getTime()>=fileDate.getTime()){
        alert("WRONG FILE.The file is uploaded already")
        window.location.reload();
      }else{





      
      const proceed_nextdaystp = window.confirm(`The last uploaded stp file date is ${data_date[0].stp_File_uploded_date} and the current STP file date is ${dateStr}. make sure the STP file date. Are you sure you want to proceed?`);
     
     
      if (proceed_nextdaystp) {
        // Proceed with the action
       // alert("second day stp");
      
      } else {
        //alert("If there is no Previous day STP file then click 'Proceed without trade file Button' and this Operation is cancelled");
        
        window.location.reload();
      }


      const dateStr1 = data_date[0].stp_File_uploded_date;
     
      // Parsing the file date string
      if(dateStr1==undefined){
        alert("Error previous Day STP file not uploaded . If there is no Previous day STP file then click 'Proceed without trade file Button' and this Operation is cancelled")
        window.location.reload();
      }else{
      const fileDateParts1 = dateStr1.split(".");

      const fileDay1 = parseInt(fileDateParts1[0]);
      const fileMonth1 = parseInt(fileDateParts1[1]) - 1; // JavaScript months are 0-based
      const fileYear1 = parseInt(fileDateParts1[2]);
      const preday_stp_fileDate = new Date(fileYear1, fileMonth1, fileDay1);

          // if(preday_stp_fileDate<fileDate){

          // }else if()
          setFiles([...files, ...selectedFiles]);
      }

    }
    } else {
      // Handle the case when the file date is after the holding report date
      alert("unknown")
    }

  }


  }
}catch(err){
  console.log("un known err :   "+ err)
}

}

  const handleInputChange = (e) => {
    setInputData(e.target.value);
  };

  const handleSubmit = async () => {

    if(!confirmBankbalance_val){
      alert("check the bank balance and click the check Box to Confirm it.")
      return
    }
    // Validate form inputs
    if (!validateForm()) {
      return;
    }
        


    const formData = new FormData();

    // Append each file to the formData
    for (const file of files) {
      formData.append("files", file);
    }

    formData.append("date", inputData);
    formData.append("forSTPfileOP_id", data_date[0]._id);
    formData.append("enableMail", enableMail);
    formData.append("updateBankbalance", updateBankbalance);

    try {
      setSubbtn(true);
     // if(data_date[0].stp_File_uploded_date){
      const response = await Axios.post("/STPfileOperation", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
   

      console.log(response.data);

      if (response.status === 200) {
        if (response.data.message === "Report sent successfully!") {
          // Handle success, e.g., show a success message
          console.log("Report sent successfully!");
          setSubbtn(false);
          // Clear the form
          setFiles([]);
          setInputData("");
          // Show alert message
          setAlertmsgsuccess(true);
          setTimeout(() => {
            window.location.reload();
          }, 300);
        } else if (response.data.message === "Date Already in Database") {
          alert("Date is Already used in Database...");
          window.location.reload();
        } else {
          setalertmsgfailure(true);
          setSubbtn(false);
          // Handle error, e.g., show an error message
          console.error("Error report not sent...");
        }
      } else {
        alert("Internal Server Error...");
      }





    // }else{
    //   //alert("nofile op first time uploading STP file")
    //   const response = await Axios.post("/STPfileOperation_Firsttime", formData, {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //   });


    // }




    } catch (error) {
      // console.error('Error:', error);
      alert("NOT FOUND");
    }
  };

  const validateForm = () => {
    // Basic validation for the presence of two files
    if (files.length !== 1) {
      // alert('Please select exactly 2 OR 3 files.');
      alert("Please select exactly 1 files.");

      return false;
    }
    
   

    return true;
  };

  const [btnloadingnew,setBtnloadingnew]=useState(false)


  const createOperation = async (formatedZerothsfile_date) => {
    if(!confirmBankbalance_val){
      alert("check the bank balance and click the check Box to Confirm it.")
      return
    }
   setBtnloadingnew(true)
   const manual_stp_file_date={
    manual_stp_file_date:formatedZerothsfile_date,
    updateBankbalance:updateBankbalance
   }
    const response = await Axios.post("/withoutSTPfileOperation",manual_stp_file_date);
    console.log(response.data);
    window.location.reload();
  }

  const [btnwaite,setBtnwaite]=useState(false)

 const todayNotrade=async()=>{
  if(!confirmBankbalance_val){
    alert("check the bank balance and click the check Box to Confirm it.")
    return
  }
  setBtnwaite(true)
  const currentDate = new Date();
  const options = { timeZone: 'Asia/Kolkata', day: '2-digit', month: '2-digit', year: 'numeric' };
  let indianDate = currentDate.toLocaleDateString('en-IN', options);
  indianDate = indianDate.replace(/\//g, '.');

  if(data_date[0].stp_File_uploded_date===indianDate){
    alert('today trade operations already done.if You facing any issue start from module 1.')
    window.location.reload();
    return
  }
      const proceed_Notradetoday = window.confirm(`The last uploaded stp file date is ${data_date[0].stp_File_uploded_date} and no trades Today. Are you sure you want to proceed?`);
   
   
      if(proceed_Notradetoday){
    const tempdata={
      lastOP_stp_File_uploded_date: data_date[0].stp_File_uploded_date,
      updateBankbalance:updateBankbalance
    }
    const response = await Axios.post("/NotradeToday",tempdata);
    console.log(response.data);
    window.location.reload();

    }else{
      window.location.reload();
    }

}



function alertClose(amount){
  if(amount!==undefined){
    setUpdateBankbalance(amount)
  }else{
    setUpdateBankbalance(data_date[0].bankbalance)
  }
 
  setAlertpop()
}

function confirmBankbalance(){
  if(updateBankbalance===0){
    setUpdateBankbalance(data_date[0].bankbalance)
  }
  setConfirmBankbalance_val(!confirmBankbalance_val)
}

  return (
    <>
    {
      alertpop?
      <BankbalPopup alertClose={alertClose} bankbalance={data_date[0].bankbalance} sell_amount={data_date[0].sell_amount}></BankbalPopup>
    :''
    }
     <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
        }}
      >
        <div className="appdivnew card-body">
          {/* <p className='btn btn-secondary'>Logout</p> */}

          <h2>Module 2</h2>
          <p>
            Zerodha Holding file 1 & STP file Converting to Zerodha Holding File
            2 Operation{" "}
          </p>
          <p>Zerodha Holding File 2 - {formatedZerothsfile_date}</p>
          <p>Last Updated STP file Data Tradedate : {data_date.length>0?data_date[0].stp_File_uploded_date?data_date[0].stp_File_uploded_date:"Not uploded":"No Files"}</p>
          <br></br>
          {/* <div>
          <label>Zerodha Holdings File - 1</label>
          <input
            className='form-control '
            accept='.xlsx'
            onChange={handleFileChange}
            id='formFileLg'
            type='file'
          />
        </div> */}

          {/* <div>
          <label>Zerodha Bank Balance File - 1</label>
          <input
            className='form-control '
            accept='.xlsx'
            onChange={handleFileChange}
            id='formFileLg'
            type='file'
          />
        </div> */}



         
          {
            data_date[0]?.stp_File_uploded_date==undefined?
            <>
            {
            btnloadingnew?<h3>Creating Operation...</h3>:
            <button
            style={{
              marginLeft: "-3%",
              display: `${forsubbtn ? "none" : null}`,
            }}
            className="btn btn-outline-warning"
            onClick={()=>createOperation(formatedZerothsfile_date)}
            disabled={forsubbtn}
          >
            Proceed without previous Day Trade File
          </button>
          }
          </>
          :''
          }

          {
            data_date[0]?.stp_File_uploded_date? 
            btnwaite?" Loading ...":<button style={{
              margin:'2%',
              marginLeft: "-1%",
            }} className="btn btn-info" onClick={()=>todayNotrade()}>Click When No Trades Today</button>

         :''
          }

         
          




          <br />
          <div>
            <label>STP Trade File</label>
            <input
              className="form-control "
              accept=".xlsx"
              onChange={handleFileChange}
              id="formFileLg"
              type="file"
            />
          </div>
          {/* <br />
        <div 
        //style={{marginLeft:"-12%"}}
        >
          <label>Date</label>
          <input
          //style={{width:'197%'}}
            className='form-control '
           // accept='.xlsx'
            onChange={handleInputChange}
            id='date'
            type='date'
          />
        </div> */}

          <br />

          <div className="form-check">
          <input onChange={()=>setEnableMail(!enableMail)} className="form-check-input" type="checkbox" value="" id="flexCheckChecked"  checked={enableMail}/>
          <label className="form-check-label" for="flexCheckChecked">
            Click to enable mail for Previous day STP file
          </label>
        </div>
        <p></p>

        <div style={{display:'flex',justifyContent:'center',width:'100%'}}>
        <p style={{fontWeight:'bold',color:'darkred',textDecoration:'underline'}} onClick={()=>setAlertpop(true)}>
         
         Bank Balance :{updateBankbalance!==0?updateBankbalance:data_date[0]?.bankbalance}
        
        </p>
       
        <input  onChange={()=>confirmBankbalance()} className="form-check-input" type="checkbox" value="" id="flexCheckChecked"  checked={confirmBankbalance_val}/>
        
        </div>

          <button
            style={{
              marginLeft: "-3%",
              display: `${forsubbtn ? "none" : null}`,
            }}
            className="btn btn-success"
            onClick={handleSubmit}
            disabled={forsubbtn}
          >
            Submit
          </button>
          <br></br>
          {/* <p style={{marginLeft:'-3%'}} className='btn btn-outline-warning'>View List</p> */}
          {forsubbtn ? (
            <div
              style={{
                width: "10%",
                marginLeft: "-3%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div class="spinner-grow text-primary" role="status"></div>
              <div class="spinner-grow text-secondary" role="status"></div>
              <div class="spinner-grow text-success" role="status"></div>
            </div>
          ) : null}

          <div className="alert-box">
            {alertmsgsuccess ? (
              <h3 style={{ color: "#198754" }}>
                Operation Created Successfully
              </h3>
            ) : alertmsgfailure ? (
              <h3 style={{ color: "red" }}>Error in Sending Report.</h3>
            ) : (
              ""
            )}
          </div>
          {/* {
          forsubbtn? null: <p onClick={() => setAlertpoup(true)} className='p-changepwd'>
          Change File Password
        </p>
        } */}
         
        <a style={{marginLeft:"-3.3%"}} href="/pagenavigator">Go Back</a>

        </div>
      
        <div className="appdivnew2">
          <h3>List Of Operations</h3>
          <OPlist></OPlist>
        </div>
      </div>
    </>
  );
}

export default App2Stp;
