import React, { useState } from "react";
import "./Filesender.css";
import Axios from "../../axios";
function Changepwd(props) {
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({
    password: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    // Clear corresponding error when user types
    setErrors({
      ...errors,
      [e.target.name]: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Password validation logic
    if (formData.password === "" || formData.confirmPassword === "") {
      setErrors({
        password: "Please enter both password and confirm password.",
        confirmPassword: "",
      });
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      setErrors({
        password: "",
        confirmPassword: "Passwords do not match. Please re-enter.",
      });
      return;
    }
    console.log(formData);
    // API call to change the password
    // Replace the following code with your actual API call
    // Example:
    // api.changePassword(formData.password)
    //   .then(response => {
    //     // Handle success
    //     console.log('Password changed successfully');
    //   })
    //   .catch(error => {
    //     // Handle error
    //     console.error('Error changing password', error);
    //   });

    const response = await Axios.post("/ChangePwd", {
      password: formData.password,
    });
    if (response.status === 200) {
      if (response.data.message === "Password Updated...") {
        // Reset form data and errors after successful change
        setFormData({
          password: "",
          confirmPassword: "",
        });

        setErrors({
          password: "",
          confirmPassword: "",
        });
      }
    } else {
      alert("Error in password change");
    }

    props.closePopup();
  };

  return (
    <div className="alertform">
      <form onSubmit={handleSubmit}>
        <div className="modal-header">
          <h5 className="modal-title">Change File Password</h5>

          <span
            onClick={() => props.closePopup()}
            style={{ fontSize: "20px" }}
            className="close btn"
            aria-hidden="false"
          >
            &times;
          </span>
        </div>
        <div className="form-group">
          {/* <label htmlFor="passwor">Enter Password</label> */}
          <input
            type="password"
            className="form-control"
            placeholder="Enter Password"
            name="password"
            value={formData.password}
            onChange={handleChange}
          />
          {errors.password && (
            <small className="text-danger">{errors.password}</small>
          )}
        </div>
        <div className="form-group">
          {/* <label htmlFor="confirmPassword">Confirm Password</label> */}
          <input
            type="password"
            className="form-control"
            placeholder="Confirm Password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
          />
          {errors.confirmPassword && (
            <small className="text-danger">{errors.confirmPassword}</small>
          )}
        </div>
        <button
          style={{ width: "30%", marginLeft: "33%" }}
          type="submit"
          className="btn btn-outline-info"
        >
          Submit
        </button>
      </form>
    </div>
  );
}

export default Changepwd;
