import axios from "axios";

const instance = axios.create({
  //baseURL: 'http://localhost:5003/api',
 // baseURL:'http://192.168.29.198:5003/api',
 //baseURL: "https://134.209.151.30:5003/api",
 
  //baseURL:'http://api-pwm-ai.bot.nu:8080/api',

  baseURL: 'http://portfolioapp.in:5003/api',
  headers: {
    Authorization: `${localStorage.getItem("react_app_token")}`,
    "Content-Type": "application/json",
  },
});

export default instance;
