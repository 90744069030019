import React from "react";
import { Link, useNavigate } from "react-router-dom";
function Pagenavigater() {
  return (
    <div
      style={{
        display: "grid",
        placeItems: "center",
        height: "100vh",
        alignContent: "center",
      }}
    >
      <Link
        to={"/converterv1"}
        type="button"
        class="btn btn-outline-success btn-lg btn-block"
      >
        <h4>Module 1 - Axis File </h4>
        <p>Note : No need to upload everytime. </p>
      </Link>
      <Link
        to={"/converterv2"}
        style={{ marginTop: "50px" }}
        type="button"
        class="btn btn-outline-warning btn-lg btn-block"
      >
       
        <h4> Module 2 - STP File </h4>
        <p>Note : If any error in previous Mail start with module 1.</p>
      </Link>

      <Link
        to={"/traderecon"}
        style={{ marginTop: "50px" ,marginLeft:'-20px'}}
        type="button"
        class="btn btn-outline-info btn-lg btn-block"
      >
       
        <h5> Traderecon </h5>
       
      </Link>
    </div>
  );
}

export default Pagenavigater;
