import React, { useEffect, useState } from "react";
import Axios from "../../axios";
import { Link, useNavigate } from "react-router-dom";
function OPlist() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    fetchData();
  }, []);

  let fetchData = async () => {
    try {
      const res = await Axios.post("/getoperations/get");
      setData(res.data);
      console.log(res.data)
    } catch (error) {
      console.log(error);
    }
  };
  const deleteOp = async (input) => {
    await Axios.post("/getoperations/delete", input);
    fetchData();
  };
  return (
    <table className="table  table-bordered border-secondary">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Trade Date</th>
          {/* <th scope="col">Insert File</th> */}
          <th scope="col">File Operation</th>
          {/* <th scope="col">Delete</th> */}
          <th scope="col">Bank Balance</th>
        </tr>
      </thead>
      <tbody>
        {data.map((input) => {
          return (
            <tr>
              <th scope="row">{data.indexOf(input) + 1}</th>
              <td>{input.tradedate}</td>
              {/* <td>
                      <input
                        className='form-control '
                        accept='.xlsx'
                        //onChange={handleFileChange}
                        id='formFileLg'
                        type='file'
                      />
                      </td> */}
              <td>
                <p
                  onClick={() => navigate(`/comparisonpage/${input._id}`)}
                  className="btn btn-sm btn-outline-success"
                >
                  Check AXIS File
                </p>
              </td>
              <td>{input.updateBankbalance}</td>
              {/* <td>
                <p
                  onClick={() => deleteOp(input)}
                  className="btn btn-sm btn-outline-danger"
                >
                  Delete
                </p>
              </td> */}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default OPlist;
