import "./App.css";
import AnalyticsList from "./Components/Analytics/AnalyticsList";
import Filesender from "./Components/FileSender/Filesender";
import Loginpage from "./Components/FileSender/Loginpage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Pagenavigater from "./Components/FileSender/Pagenavigater";
import Converterv2 from "./Components/FileSender/Converterv2";
import ComparisonPage from "./Components/FileSender/ComparisonPage";
import App2Stp from "./Components/FileSender/App2Stp";
import Traderecon from "./Components/TradeRecon/Traderecon";
function App() {
  return (
    <div className="App">
      {/* <AnalyticsList></AnalyticsList> */}
      {/*  */}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Loginpage></Loginpage>} />
          <Route
            path="/pagenavigator"
            element={<Pagenavigater></Pagenavigater>}
          />
          <Route path="/converterv1" element={<Filesender></Filesender>} />
          {/* <Route path="/converterv2" element={<Converterv2></Converterv2>} />      */}
          <Route path="/converterv2" element={<App2Stp></App2Stp>} />
          <Route
            path="/comparisonpage/:id"
            element={<ComparisonPage></ComparisonPage>}
          />
           <Route path="/traderecon" element={<Traderecon></Traderecon>} />
        </Routes>
       
      </BrowserRouter>
    </div>
  );
}

export default App;
