import React, { useState } from 'react';

function BankbalPopup(props) {
  // State to hold form data and error messages
  const [formData, setFormData] = useState({
    amount: '',
    errors: {
      amount: ''
    }
  });

  // Validation function
  const validateForm = () => {
    let valid = true;
    const errors = {};

    // Check if amount is empty
    if (!formData.amount.trim()) {
      errors.amount = 'Amount is required';
      valid = false;
    }

    setFormData(prevState => ({
      ...prevState,
      errors
    }));

    return valid;
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Form is valid, handle submission
      console.log('Form submitted:', formData);
     
      props.alertClose(formData.amount)
    }
  };

  // Handle input change
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [id]: value,
      errors: {
        ...prevState.errors,
        [id]: '' // Clear previous error message
      }
    }));
  };

  return (
    <div style={{borderRadius:'20px',backgroundColor:'rgb(176, 171, 165)',height:'65%',width:'40%',position:'absolute',textAlign:'center',margin:'10%',alignItems:'center'}} className="container">
      <h4 style={{marginTop:'10%'}}>Bank Balance</h4>
      <p>Amount :{parseFloat(props.bankbalance).toFixed(2)}</p>
      <p>SELL Amount :{parseFloat(props.sell_amount).toFixed(2)}</p>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="amount" className="form-label">Change Amount</label>
          <input type="text" className="form-control" id="amount" placeholder="Enter Amount" value={formData.amount} onChange={handleChange} />
          {formData.errors.amount && <div className="text-danger">{formData.errors.amount}</div>}
        </div>
        <button type="submit" className="btn btn-primary mr-2">Submit</button>
        <p></p>
        <button onClick={()=>props.alertClose()} className="btn btn-danger mr-2">Close</button>
      </form>
    </div>
  );
}

export default BankbalPopup;
