import React,{useState} from 'react'
import Axios from '../../axios';

import '../FileSender/Filesender.css';

function Traderecon() {
    const [files, setFiles] = useState([]);
    const [inputData, setInputData] = useState('');
  
    const [alertmsgsuccess, setAlertmsgsuccess] = useState(false);
    const [alertmsgfailure, setalertmsgfailure] = useState(false);
  
    const [forsubbtn, setSubbtn] = useState(false);
  
    //const [alertpopup, setAlertpoup] = useState(false);
  
    const handleFileChange = (e) => {
      const selectedFiles = e.target.files;

      if(files.length===0){
        const fileName = e.target.files[0].name.toLowerCase();
        if (fileName.includes('(t)')) {
            //console.log('File name includes holdingreport details');
            setFiles([...files, ...selectedFiles]);
        } else {
            alert('upload Current trade file (t)');
            window.location.reload();
        }
      }



      if(files.length===1){
        const fileName = e.target.files[0].name.toLowerCase();
        if (fileName.includes('(t-1)')) {
            //console.log('File name includes bankbalance details');
            setFiles([...files, ...selectedFiles]);
        } else {
            alert('upload pre-day trade file (t-1)');
            window.location.reload();
        }
      }

      if(files.length===2){
        const fileName = e.target.files[0].name.toLowerCase();
        if (fileName.includes('trade upload file')) {
            //console.log('File name includes bankbalance details');
            setFiles([...files, ...selectedFiles]);
        } else {
            alert('File name does not include bankbalance details');
            window.location.reload();
        }
      }

      


     
    };
  
    const handleInputChange = (e) => {
      setInputData(e.target.value);
    };
  
    const handleSubmit = async () => {
      // Validate form inputs
      if (!validateForm()) {
        return;
      }
  
      const formData = new FormData();
  
      // Append each file to the formData
      for (const file of files) {
        formData.append('files', file);
      }
  
     
      try {
        setSubbtn(true);
        const response = await Axios.post('/traderecon', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
  
        console.log(response.data);
  
        if (response.status === 200) {
            setSubbtn(false)
            alert("Mail sent successfully...")
            window.location.reload();
        } else {
          alert('Internal Server Error...');
        }
      } catch (error) {
        // console.error('Error:', error);
        alert('NOT FOUND');
      }
    };
  
    const validateForm = () => {
      // Basic validation for the presence of two files
      if (files.length  !==3) {
        // alert('Please select exactly 2 OR 3 files.');
        alert('Please select exactly 3 files.');

        return false;
      }
      //   if (!inputData.trim()) {
      //   alert('Please enter a value for inputData.');
      //   return false;
      // }
    
      return true;
    };
  return (
    <div>
      

<div style={{display:'flex',alignItems:"center",height:'100vh',width:'100vw'}}>





    
<div className='appdivnew card-body'>
  {/* <p className='btn btn-secondary'>Logout</p> */}

    <h2>PORTFOLIO APPRAISAL (TRADE-RECON)	</h2>
  
    <br></br>
    <div>
      <label>PORTFOLIO APPRAISAL (t)	</label>
      <input
        className='form-control '
        accept='.csv'
        onChange={handleFileChange}
        id='formFileLg'
        type='file'
      />
    </div>
    <br></br>
    <div>
      <label>PORTFOLIO APPRAISAL (t-1)</label>
      <input
        className='form-control '
        accept='.csv'
        onChange={handleFileChange}
        id='formFileLg'
        type='file'
      />
    </div>
    <br />
    <div>
      <label>Trade Upload File</label>
      <input
        className='form-control '
        accept='.xlsx'
        onChange={handleFileChange}
        id='formFileLg'
        type='file'
      />
    </div>
    {/* <br />
    <div 
    //style={{marginLeft:"-12%"}}
    >
      <label>Date</label>
      <input
      //style={{width:'197%'}}
        className='form-control '
       // accept='.xlsx'
        onChange={handleInputChange}
        id='date'
        type='date'
      />
    </div> */}
    <br />
    <button style={{marginLeft:'-3%',display:`${forsubbtn?'none':null}`}}
      className='btn btn-success'
      onClick={handleSubmit}
      disabled={forsubbtn}
    >
      Submit
    </button>
    <br></br>
    {/* <p style={{marginLeft:'-3%'}} className='btn btn-outline-warning'>View List</p> */}
    {
      forsubbtn? <div style={{width:'10%',marginLeft:"-3%",display:'flex',justifyContent:'space-between'}}>
      <div class="spinner-grow text-primary" role="status"> 
      </div>
      <div class="spinner-grow text-secondary" role="status">
      </div>
      <div class="spinner-grow text-success" role="status">
      </div>
  </div>:null

    }


    <div className='alert-box'>
      {alertmsgsuccess ? (
        <h3 style={{ color: '#198754' }}>Operation Created Successfully</h3>
      ) : alertmsgfailure ? (
        <h3 style={{ color: 'red' }}>Error in Sending Report.</h3>
      ) : (
        ''
      )}
    </div>
    {/* {
      forsubbtn? null: <p onClick={() => setAlertpoup(true)} className='p-changepwd'>
      Change File Password
    </p>
    } */}

   
    </div>

        

    </div>
    </div>
  )
}

export default Traderecon
