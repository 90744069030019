import React, { useState, useEffect } from "react";
import Axios from "../../axios";
import Changepwd from "./Changepwd";

import "./Filesender.css";
import { useNavigate, useParams } from "react-router-dom";
import ISINnotMatchTable from "./ISINnotMatchTable";

const ComparisonPage = () => {
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    fetchData();
  }, []);

  let fetchData = async () => {
    try {
      const res = await Axios.post("/getoperations/get");
      if (res.data.length > 0) {
      } else {
        navigate("/converterv2");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [files, setFiles] = useState([]);
  const [inputData, setInputData] = useState("");

  const [alertmsgsuccess, setAlertmsgsuccess] = useState(false);
  const [alertmsgfailure, setalertmsgfailure] = useState(false);

  const [forsubbtn, setSubbtn] = useState(false);

  const [alertpopup, setAlertpoup] = useState(false);

  const [errorINcol, setErroINcol] = useState([]);

  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    setFiles([...files, ...selectedFiles]);
  };

  const handleInputChange = (e) => {
    setInputData(e.target.value);
  };

  const handleSubmit = async () => {
    // Validate form inputs
    if (!validateForm()) {
      return;
    }

    const formData = new FormData();

    // Append each file to the formData
    for (const file of files) {
      formData.append("files", file);
    }

    formData.append("inputData", inputData);
    

    try {
      setSubbtn(true);
      const response = await Axios.post(`/comparison/${params.id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      //   console.log(response.data);

      if (response.status === 200) {
        if (response.data.message === "Report sent successfully!") {
          // Handle success, e.g., show a success message
          console.log("Report sent successfully!");
          setSubbtn(false);
          // Clear the form
          setFiles([]);
          setInputData("");
          // Show alert message
          setAlertmsgsuccess(true);
          setTimeout(() => {
            //window.location.reload();
            navigate("/converterv2");
          }, 300);
        } else if (response.data) {
          setErroINcol(response.data);
          console.log(response.data);
        } else {
          setalertmsgfailure(true);
          setSubbtn(false);
          // Handle error, e.g., show an error message
          console.error("Error report not sent...");
        }
      } else {
        alert("Internal Server Error...");
      }
    } catch (error) {
      // console.error('Error:', error);
      alert("NOT FOUND");
    }
  };

  const validateForm = () => {
    // Basic validation for the presence of two files
    if (files.length !== 2) {
      alert("Please select exactly two files.");
      return false;
    }
    //   if (!inputData.trim()) {
    //   alert('Please enter a value for inputData.');
    //   return false;
    // }

    return true;
  };

  const closePopup = () => {
    setAlertpoup(false);
  };

  return (
    <>
      {alertpopup ? <Changepwd closePopup={closePopup}></Changepwd> : ""}

      {errorINcol.length > 0 ? (
        <ISINnotMatchTable errorINcol={errorINcol}></ISINnotMatchTable>
      ) : (
        <div style={{backgroundColor:'#FB8888'}} className="appdiv card-body">
          {/* <p className='btn btn-secondary'>Logout</p> */}

          <h2>Axis files Comparison</h2>
          <br></br>
          <div>
            <label>Holdings Report File - 2</label>
            <input
              className="form-control "
              accept=".xlsx"
              onChange={handleFileChange}
              id="formFileLg"
              type="file"
            />
          </div>
          <br></br>
          <div>
            <label>Bank Balance File - 2</label>
            <input
              className="form-control "
              accept=".xlsx"
              onChange={handleFileChange}
              id="formFileLg"
              type="file"
            />
          </div>
          <br />
          <button
            style={{
              marginLeft: "-3%",
              display: `${forsubbtn ? "none" : null}`,
            }}
            className="btn btn-success"
            onClick={handleSubmit}
            disabled={forsubbtn}
          >
            Submit
          </button>
          {forsubbtn ? (
            <div
              style={{
                width: "10%",
                marginLeft: "-3%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div class="spinner-grow text-primary" role="status"></div>
              <div class="spinner-grow text-secondary" role="status"></div>
              <div class="spinner-grow text-success" role="status"></div>
            </div>
          ) : null}

          <div className="alert-box">
            {alertmsgsuccess ? (
              <h3 style={{ color: "#198754" }}>Mail Sent Successfully</h3>
            ) : alertmsgfailure ? (
              <h3 style={{ color: "red" }}>Error in Sending Report.</h3>
            ) : (
              ""
            )}
          </div>
          {/* {
          forsubbtn? null: <p onClick={() => setAlertpoup(true)} className='p-changepwd'>
          Change File Password
        </p>
        } */}
          <br></br>
        <a style={{marginLeft:"-3.3%"}} href="/converterv2">Go Back</a>

        </div>
      )}
    </>
  );
};

export default ComparisonPage;
