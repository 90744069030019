import React, { useEffect, useState } from "react";
import Axios from "../../axios";
import { Link, useNavigate } from "react-router-dom";
function ISINnotMatchTable(props) {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    //fetchData();
  }, []);

  return (
    <div style={{ padding: "5%" }}>
      <table className="table  table-bordered border-secondary">
        <thead>
          <tr>
            <th scope="col">Error Column NO 1: </th>
            <th scope="col">Error Column NO 2: </th>
            <th scope="col">ISIN</th>
            {/* <th scope="col">Insert File</th> */}
            <th scope="col">QTY in Axis File</th>
            <th scope="col">QTY in Software File OR Zerodha Holding File 2</th>
            <th scope="col">QTY Difference</th>
          </tr>
        </thead>
        <tbody>
          {props.errorINcol.map((input) => {
            return (
              <tr>
                <th scope="row">{input.column1}</th>
                <th scope="row">{input.column2}</th>
                <td>{input.isin}</td>
                <td>{input.count}</td>
                <td>{input.value2}</td>
                <td>
                  {input.value2 - input.count < 0
                    ? (input.value2 - input.count) * -1
                    : (input.value2 - input.count) * 1}
                </td>

                {/* <td><p onClick={()=>navigate(`/comparisonpage/${input._id}`)} className='btn btn-sm btn-outline-success'>Check AXIS File</p></td>
                    <td><p onClick={()=>deleteOp(input)} className='btn btn-sm btn-outline-danger'>Delete</p></td> */}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default ISINnotMatchTable;
