import React, { useState } from "react";
import Axios from "../../axios";
import Changepwd from "./Changepwd";

import "./Filesender.css";
import { useNavigate } from "react-router-dom";

const FileSender = () => {
  const navigate = useNavigate();
  const [enableMail, setEnableMail] = useState(false);

  const [files, setFiles] = useState([]);
  const [inputData, setInputData] = useState("");

  const [alertmsgsuccess, setAlertmsgsuccess] = useState(false);
  const [alertmsgfailure, setalertmsgfailure] = useState(false);

  const [forsubbtn, setSubbtn] = useState(false);

  const [alertpopup, setAlertpoup] = useState(false);

  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    setFiles([...files, ...selectedFiles]);
  };

  const handleInputChange = (e) => {
    setInputData(e.target.value);
  };

  const handleSubmit = async () => {
    // Validate form inputs
    if (!validateForm()) {
      return;
    }

    const formData = new FormData();

    // Append each file to the formData
    for (const file of files) {
      formData.append("files", file);
    }

    formData.append("inputData", inputData);
    formData.append("enableMail", enableMail);

    try {
      setSubbtn(true);
      const response = await Axios.post("/SentReport2", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log(response.data);

      if (response.status === 200) {
        if (response.data.message === "Report sent successfully!") {
          // Handle success, e.g., show a success message
          console.log("Report sent successfully!");
          setSubbtn(false);
          // Clear the form
          setFiles([]);
          setInputData("");
          // Show alert message
          setAlertmsgsuccess(true);
          setTimeout(() => {
            // window.location.reload();
            navigate("/converterv2");
          }, 300);
        } else {
          setalertmsgfailure(true);
          setSubbtn(false);
          // Handle error, e.g., show an error message
          console.error("Error report not sent...");
        }
      } else {
        alert("Internal Server Error...");
      }
    } catch (error) {
      // console.error('Error:', error);
      alert("NOT FOUND");
    }
  };

  const validateForm = () => {
    // Basic validation for the presence of two files
    if (files.length !== 2) {
      alert("Please select exactly two files.");
      return false;
    }
    //   if (!inputData.trim()) {
    //   alert('Please enter a value for inputData.');
    //   return false;
    // }

    return true;
  };

  const closePopup = () => {
    setAlertpoup(false);
  };

  return (
    <>
      {alertpopup ? <Changepwd closePopup={closePopup}></Changepwd> : ""}

      <div className="appdiv card-body">
        {/* <p className='btn btn-secondary'>Logout</p> */}

        <h2>Module 1</h2>
        {/* <p>Mail will Sent directly There is no comparison.</p> */}
        <p>Files are kept in software for file comparison</p>
        <br></br>
        <div>
          <label>Holdings Report File</label>
          <input
            className="form-control "
            accept=".xlsx"
            onChange={handleFileChange}
            id="formFileLg"
            type="file"
          />
        </div>
        <br></br>
        <div>
          <label>Bank Balance File</label>
          <input
            className="form-control "
            accept=".xlsx"
            onChange={handleFileChange}
            id="formFileLg"
            type="file"
          />
        </div>

       

        <br />
        <div className="form-check">
          <input onChange={()=>setEnableMail(!enableMail)} className="form-check-input" type="checkbox" value="" id="flexCheckChecked"  checked={enableMail}/>
          <label className="form-check-label" for="flexCheckChecked">
            Click to enable mail
          </label>
        </div>
        <p></p>
        <button
          style={{ marginLeft: "-3%", display: `${forsubbtn ? "none" : null}` }}
          className="btn btn-success"
          onClick={handleSubmit}
          disabled={forsubbtn}
        >
          Submit
        </button>
        {forsubbtn ? (
          <div
            style={{
              width: "10%",
              marginLeft: "-3%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div class="spinner-grow text-primary" role="status"></div>
            <div class="spinner-grow text-secondary" role="status"></div>
            <div class="spinner-grow text-success" role="status"></div>
          </div>
        ) : null}

        <div className="alert-box">
          {alertmsgsuccess ? (
            // <h3 style={{ color: "#198754" }}>Mail Sent Successfully</h3>
            <h3 style={{ color: "#198754" }}>Files Stored in Software Successfully...</h3>
          ) : alertmsgfailure ? (
            <h3 style={{ color: "red" }}>Error in Sending Report.</h3>
          ) : (
            ""
          )}
        </div>
        <br></br>
        <a style={{marginLeft:"-3.3%"}} href="/pagenavigator">Go Back</a>
        {/* {
          forsubbtn? null: <p onClick={() => setAlertpoup(true)} className='p-changepwd'>
          Change File Password
        </p>
        } */}
      </div>
    </>
  );
};

export default FileSender;
